var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v("Workflow Admin Panel")]),_c('v-spacer'),(_vm.user && (_vm.user.username == 'test' || _vm.user.username == 'ak7948'))?_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"text":""}},on),[_c('v-icon',[_vm._v("mdi-apps")])],1)]}},{key:"default",fn:function(){return [_c('v-card',{attrs:{"width":"300"}},[_c('v-item-group',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"text-center pa-2",attrs:{"height":"80","width":"80","flat":"","to":"/"},on:{"click":toggle}},[_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-text-box-check-outline")]),_vm._v(" EDO ")],1)]}}],null,false,588005011)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"text-center pa-2",attrs:{"height":"80","width":"80","flat":""},on:{"click":toggle}},[_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-account-box-multiple")]),_vm._v(" HR ")],1)]}}],null,false,4037694626)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"text-center pa-2",attrs:{"height":"80","width":"80","flat":""},on:{"click":toggle}},[_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-card-account-details-outline")]),_vm._v(" Tabel ")],1)]}}],null,false,2032723448)})],1)],1)],1)],1)],1)]},proxy:true}],null,false,58408274)}):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"300","tile":""}},[_c('v-app-bar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"mx-auto"},[_vm._v("ADM JIZZAKH ISM")])],1),_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$t('message.menu')))]),_c('v-list-item-group',{attrs:{"color":"rgb(63, 81, 181)"}},[_vm._l((_vm.links),function(item,i){return _c('v-list-item',{key:i,attrs:{"router":"","to":item.route}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('message.logout') + ' ('+_vm.username+')'))])],1)],1)],2)],1)],1)]],2),_c('v-content',[_c('router-view')],1),_c('v-footer',{attrs:{"color":"indigo","app":""}},[_c('span',{staticClass:"white--text"},[_vm._v("© 2019")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }